import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import '././styles/app.css';
import {AuthPage} from "./pages/auth";
import {NewUserPage} from "./pages/new-user";
import {ProtectedRoute} from "./components/protected-route";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/auth" />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/new-user"
          element={
            <ProtectedRoute>
              <NewUserPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
