import React, {useEffect, useState} from 'react';
import {Layout} from "../components/layout";
import {NewUserForm} from "../components/new-user";

export const NewUserPage = () => {
  return (
    <Layout>
      <NewUserForm/>
    </Layout>
  );
}