import React, { useState } from 'react';
import './../styles/form.css';
import styled from "styled-components";

export const AuthForm: React.FC<{ onLogin: (email: string, password: string) => void }> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(email, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <STitle>Auth form</STitle>
      <SAntryArea>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={email ? 'active' : ''}
          required
        />
        <SLabel className={email ? 'active' : ''}>Enter email</SLabel>
      </SAntryArea>
      <SAntryArea>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={password ? 'active' : ''}
          required
        />
        <SLabel className={password ? 'active' : ''}>Enter password</SLabel>
      </SAntryArea>
      <SButton type="submit">Submit</SButton>
    </form>
  );
}

export const STitle = styled.h1`
  margin: 1rem 0 2rem;
  font-weight: 500;
  font-size: 1.375rem;
  font-family: Rubik, sans-serif;
  line-height: 1.167;
  color: #1C2529;
  text-align: center;
`

export const SAntryArea = styled.div`
  position: relative;
  height: 50px;
  line-height: 50px;
  margin-bottom: 35px;

  input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    font-size: 14px;
    line-height: 50px;
    border-radius: 4px;
    border: 1px solid #B7C6D3;
    color: #275572;
    margin-bottom: 24px;
    background: transparent;
    padding-left: 15px;
    transition: border 0.1s ease;
    position: relative;
    z-index: 1111;
    

    &:focus,
    &.active {
      border: 2px solid #0094A6;
    }

    &:focus + label,
    &.active + label {
      color: #0094A6;
      height: 30px;
      line-height: 30px;
      transform: translate(-10px, -40px);
      font-size: 14px;
      padding: 0 10px;
      z-index: 1111;
    }
    
    &.selected {
      background-color: transparent;
    }
  }
`

export const SLabel = styled.label`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #275572;
  background-color: white;
  padding: 0 5px;
  transition: all 0.2s ease;
  z-index: 0;

  &.active {
    color: #0094A6;
    height: 30px;
    line-height: 30px;
    transform: translate(-10px, -40px);
    font-size: 14px;
    padding: 0 10px;
    z-index: 1111;
  }
`

export const SButton = styled.button`
  background-color: #179EAE;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  line-height: 1.75;
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 0.1px;
  cursor: pointer;
  
  &:hover {
    background-color: rgb(0, 115, 142);
    box-shadow: none;
    
  }
`