import React, {useEffect, useState} from 'react';
import './../styles/form.css';
import {registerUser} from "../api/auth-api";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Modal} from "./modal";

export const NewUserForm: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<React.ReactNode>('');
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    repeat_password: '',
    subdomain: '',
    comment: ''
  });

  // const navigate = useNavigate();

  const resetFormData = () => {
    setFormData({
      email: '',
      password: '',
      repeat_password: '',
      subdomain: '',
      comment: ''
    });
  }
  useEffect(() => {
    resetFormData();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (formData.password !== formData.repeat_password) {
      setModalMessage('Passwords do not match');
      setModalOpen(true);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setModalOpen(true);

    try {
      const response = await registerUser(formData);
      console.log(response)

      setIsLoading(false);
      setModalMessage(
        <>
          <SText>Here is your link:</SText>
          <SText>{response}</SText>
        </>
      );
      resetFormData();
    } catch (error: any) {
      // Отключаем лоадер и обрабатываем ошибки
      setIsLoading(false);

      if (error.response && error.response.data) {
        // Если сервер вернул ошибку с подробностями
        setModalMessage(`Error: ${error.response.data.message}`);
      } else {
        // Общая ошибка
        setModalMessage('Registration failed. Please check your network or try again later.');
      }

      setModalOpen(true); // Открываем модалку при ошибке
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <STitle>New user</STitle>
        <SAntryArea>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            className={formData.email ? 'active' : ''}
            required
          />
          <SLabel className={formData.email ? 'active' : ''}>Enter email</SLabel>
        </SAntryArea>
        <SAntryArea>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            autoComplete="new-password"
            required
            className={formData.password ? 'active' : ''}
          />
          <SLabel className={formData.password ? 'active' : ''}>Password</SLabel>
        </SAntryArea>
        <SAntryArea>
          <input
            type="password"
            name="repeat_password"
            autoComplete="new-password"
            value={formData.repeat_password}
            onChange={handleChange}
            required
            className={formData.repeat_password ? 'active' : ''}
          />
          <SLabel className={formData.repeat_password ? 'active' : ''}>Confirm password</SLabel>
        </SAntryArea>
        <SAntryArea>
          <input
            type="text"
            name="subdomain"
            value={formData.subdomain}
            onChange={handleChange}
            required
            className={formData.subdomain ? 'active' : ''}
          />
          <SLabel className={formData.subdomain ? 'active' : ''}>Subdomain</SLabel>
        </SAntryArea>
        <SAntryArea>
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            rows={1}
            className={formData.comment ? 'active' : ''}
          />
          <SLabel className={formData.comment ? 'active' : ''}>Enter comment</SLabel>
        </SAntryArea>
        <SButton type="submit">Submit</SButton>
      </form>
      {isModalOpen && (
        <Modal
          onClose={handleCloseModal}
          isLoading={isLoading}
          modalMessage={modalMessage}
        />
      )}
    </>
  );
};

const SText = styled.p`
  word-wrap: break-word;
  max-width: 100%;
  display: inline-block;
  margin: 10px 0;
  color: #275572;
  text-align: center;
`;

export const STitle = styled.h1`
  margin: 1rem 0 2rem;
  font-weight: 500;
  font-size: 1.375rem;
  font-family: Rubik, sans-serif;
  line-height: 1.167;
  color: #1C2529;
  text-align: center;
`

export const SAntryArea = styled.div`
  position: relative;
  height: 50px;
  line-height: 50px;
  margin-bottom: 35px;

  input, textarea {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    font-size: 14px;
    line-height: 50px;
    border-radius: 4px;
    border: 1px solid #B7C6D3;
    color: #275572;
    margin-bottom: 24px;
    background: transparent;
    padding-left: 15px;
    transition: border 0.1s ease;
    position: relative;
    z-index: 1111;

    &:focus,
    &.active {
      border: 2px solid #0094A6;
    }

    &:focus + label,
    &.active + label {
      color: #0094A6;
      height: 30px;
      line-height: 30px;
      transform: translate(-10px, -40px);
      font-size: 14px;
      padding: 0 10px;
      z-index: 1111;
    }
  }
`

export const SLabel = styled.label`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #275572;
  background-color: white;
  padding: 0 5px;
  transition: all 0.2s ease;
  z-index: 0;

  &.active {
    color: #0094A6;
    height: 30px;
    line-height: 30px;
    transform: translate(-10px, -40px);
    font-size: 14px;
    padding: 0 10px;
    z-index: 1111;
  }
`

export const SButton = styled.button`
  background-color: #179EAE;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  line-height: 1.75;
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 0.1px;
  cursor: pointer;
  
  &:hover {
    background-color: rgb(0, 115, 142);
    box-shadow: none;
    
  }
`