import React from 'react';
import styled from 'styled-components'

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <MainLayout>
      <SHeader></SHeader>

      <SContent>
        {children}
      </SContent>

      <footer></footer>
    </MainLayout>
  );
}

export const MainLayout = styled.div`
  width: 100%;
  height: 100%;
`
export const SHeader = styled.header`
  box-shadow: rgb(170, 183, 193) 0 1px 1px 0;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: static;
  background-color: #407698;
  height: 70px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
`
export const SContent = styled.main`
  margin-top: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`