import React, {useState} from "react";
import styled from "styled-components";

interface ModalProps {
  onClose: () => void;
  isLoading: boolean;
  modalMessage: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ onClose, isLoading, modalMessage }) => {
  const [isCopied, setIsCopied] = useState(false);

  const extractTextFromReactNode = (node: React.ReactNode): string => {
    if (typeof node === "string") {
      return node;
    } else if (Array.isArray(node)) {
      return node.map(extractTextFromReactNode).join('');
    } else if (React.isValidElement(node)) {
      return extractTextFromReactNode(node.props.children);
    }
    return '';
  };
  const handleCopy = async () => {
    try {
      // Извлекаем ссылку и копируем её в буфер
      const messageToCopy = typeof modalMessage === "string"
        ? modalMessage
        : extractTextFromReactNode(modalMessage);

      const link = messageToCopy.match(/https?:\/\/[^\s]+/g)?.[0]; // Извлекаем ссылку

      if (link) {
        await navigator.clipboard.writeText(link);
        setIsCopied(true);

        // Устанавливаем таймер на 2 секунды, после чего возвращаем кнопку в исходное состояние
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Не удалось скопировать текст: ", error);
    }
  };
  return (
    <SModalOverlay>
      <SModal>
        <SModalHeader>User was added!</SModalHeader>

        <SModalContent>
          {isLoading ? (
            <>
              <SText>Wait a minute...</SText>
            </>
          ) : (
            modalMessage
          )}
        </SModalContent>

        <SButtonBlock>
          <SButton
            style={{ backgroundColor: '#6ED7F9' }}
            onClick={handleCopy}
            disabled={isCopied}
          >
            {isCopied ? "Link Copied" : "Copy link"}
          </SButton>
          <SButton onClick={onClose}>Close</SButton>
        </SButtonBlock>
      </SModal>
    </SModalOverlay>
  );
}

export const SModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  inset: 0;
  -webkit-tap-highlight-color: transparent;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
`

export const SModal = styled.div`
  border-radius: 4px;
  animation: 0.3s ease-in-out 0s 1 normal none running jfQXhD;
  background: rgb(255, 255, 255);
  display: flex;
  padding: 40px 16px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
`

export const SModalHeader = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #0094A6;
`

export const SModalContent = styled.div`
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  height: 100%;
  overflow: auto;
  width: 500px;
  margin: 0 35px 30px;
`

const SText = styled.p`
  text-align: center;
`

export const SButtonBlock = styled.div`
  display: flex;
  gap: 24px;
`
export const SButton = styled.button`
  background-color: #179EAE;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 150px;
  line-height: 1.75;
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 0.1px;
  cursor: pointer;
  
  &:hover {
    background-color: rgb(0, 115, 142);
    box-shadow: none;
    
  }
`