import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization',
  },
})

//перехватчик для автоматического добавления токена к каждому запросу
apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

//логика для логина
export const login = async (email: string, password: string) => {
  try {
    const response = await apiInstance.post('/auth/login-admins', { email, password });
    const token = response.data.access_token;

    if (token) {
      localStorage.setItem('jwtToken', token);
    } else {
      console.error('No token in response');
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error during login:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error during login:', error);
    }
    throw error;
  }
}

interface RegisterUserData {
  email: string;
  password: string;
  repeat_password: string;
  subdomain: string;
  comment: string;
}

//логика для регистрации usera
export const registerUser = async (data: RegisterUserData) => {
  try {
    const response = await apiInstance.post('/create-workspace/create-workspace', data);
    return response.data;
  } catch (error) {
    console.error('Error during registration:', error);
    throw error;
  }
};