import React from "react";
import {Navigate} from "react-router-dom";

interface ProtectedRoutePropse {
  children: React.ReactNode;
}

export const ProtectedRoute = ({children} :ProtectedRoutePropse) => {
  const token = localStorage.getItem('jwtToken');

  if (!token) {
    return <Navigate to="/auth" />;
  }
  return <>{children}</>
}