import React, {useState} from 'react';
import {Layout} from "../components/layout";
import {AuthForm} from "../components/auth-form";
import {redirect, useNavigate} from "react-router-dom";
import {login} from "../api/auth-api";

export const AuthPage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleLogin = async (email: string, password: string) => {
    try {
      const result = await login(email, password);
      if (result) {
        navigate('/new-user');
      }
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
      console.error('Login failed:', error);
    }
  }

  return (
    <Layout>
      {errorMessage && <p style={{color: '#F6453B'}}>{errorMessage}</p>}
      <AuthForm onLogin={handleLogin} />
    </Layout>
  );
};